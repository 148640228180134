<template>
  <div class="flex-weather">
    <v-chart class="chart" :option="option" />
    <div class="weather">
      <ul>
        <li><span>{{info.cond_txt}}</span></li>
        <li><span class="icon-temperature"></span><span>{{info.temp}}℃</span></li>
        <li>
          <span class="icon-aqi-icon"></span><span>{{info.aqi}}<small>AQI</small></span>
        </li>
        <!-- <li>
          <span class="icon-pm10-icon"></span><span>{{info.pm10}}<small>UG/M</small></span>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import { getWeather } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  data() {
    return {
      info: {
      },
      option: {
        series: [
          {
            type: "gauge",
            radius: "120",
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.3, "#67e0e3"],
                  [0.7, "#37a2da"],
                  [1, "#fd666d"],
                ],
              },
            },
            pointer: {
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: "auto",
                width: 4,
              },
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}",
              color: "auto",
            },

            axisLabel: {
              show: false,
            },
            data: [
              {
                value: 40,
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    getData() {
      getWeather().then((res) => {
        this.info = res.data.data
        this.option.series[0].data[0].value = res.data.data.aqi
        return
      });
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<style scoped>
.flex-weather {
  display: flex;
  align-items: center;
}

.chart {
  width: 350px;
  height: 300px;
}

ul {
  margin: 0;
  padding: 0;
}

.weather {
  width: 185px;
  height: 240px;
  border: 1px solid #14556a;
  border-radius: 5px;
  padding: 30px 0 30px 25px;
}

.weather li {
  list-style: none;
  color: #affcff;
  font-size: 30px;
  display: flex;
  margin-bottom: 5px;
}

.weather li span:last-child {
  text-align: center;
  width: 120px;
}

.weather li [class^="icon-"] {
  margin-right: 18px;
}

.weather li small {
  font-size: 14px;
}
</style>
